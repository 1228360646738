<template>
	<div class="grid w-full min-h-screen grid-cols-1 md:grid-cols-5">
		<div class="flex flex-col h-full overflow-y-auto col-span-1 md:col-span-2">
			<div class="flex-1  pt-10 sm:pt-20 px-3 sm:px-0 ">
				<div class="max-w-md px-2 mx-auto">
					<img src="../../../assets/images/dscvry_logo-purple.png" class="h-6"/>
					<Text content="Reset Password" customClass="mt-8 tracking-tight" weight="medium" size="2xl" color="gray-800" />
				</div>
				<div class="flex flex-col mt-4">
					<div class="max-w-md px-2 mx-auto space-y-6 w-full">
						<Text content="Enter your password." color="gray-800" />
						<div class="-space-y-px rounded-md">
							<PasswordInput :isError="inputs.password.error" :help-text="inputs.password.error" id="password" v-model:value="inputs.password.value" :rounded="inputs.password.rounded" :type="inputs.password.type" :customClass="inputs.password.customClass" :label="inputs.password.label" :placeholder="inputs.password.placeholder" />
						</div>
						<Text
								content="Your password must be at least 8 characters and contain at least one capital letter, one symbol, and one number"
								size="sm" color="gray-800"/>
						<div class="flex justify-start max-w-md">
							<Button :content="button.label" variant="primary" :disabled="!isValid" :onClick="() => $emit('onSubmit')" :is-loading="isLoading" :custom-class="disabled ? 'bg-gray-500' : ''"/>
						</div>
					</div>
				</div>
			</div>
			<div class="flex-none">
				<div class="mb-20 sm:mb-0">
					<div class="max-w-md mx-auto my-6 px-5 sm:px-2 flex space-x-2">
						<Text content="Return to " color="gray-800" size="sm" />
						<Text @click="$emit('goTo', '/login')" content="Log In" color="primary-600" customClass="cursor-pointer self-start flex underline text-sm" />
					</div>
				</div>
			</div>
		</div>
		<div class="hidden h-full col-span-1 md:col-span-3 md:block">
			<Image :src="image.src" :alt="image.alt" width="full" height="screen" customClass="object-cover" />
		</div>
	</div>
</template>

<script>
/**
 - Combobox with text input to filter onh    basis of name property on default
 - give filter key to provide filter on a custom property
 **/
import Text from '../../atoms/Text/Text.vue';
import Link from '../../atoms/Link/Link.vue';
import Checkbox from '../../molecules/Inputs/Checkbox/Checkbox.vue';
import Image from '../../atoms/Image/Image.vue';
import PasswordInput from '../../molecules/Inputs/PasswordInput/PasswordInput.vue';
import Button from '../../atoms/Button/Button.vue';
import Icon from '../../atoms/Icons/Icons.vue';

export default {
	components: {
		Text,
		Link,
		Checkbox,
		Image,
		PasswordInput,
		Button,
		Icon
	},
	props: {
		image: {
			type: Object,
			default: () => {}
		},
		logo: {
			type: Object,
			default: () => {}
		},
		inputs: {
			type: Object,
			default: () => {}
		},
		button: {
			type: Object,
			default: () => {}
		},
		isLoading: {
			type: Boolean,
			default: false,
		}
	},
	watch: {
		inputs: {
			handler(val) {
				this.isValid = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/.test(this.inputs.password.value)
			},
			deep: true,
		}
	},
	data() {
		return {
			isValid: false,
		}
	},
};
</script>
