<template>
	<Template
			v-bind="{
				...params,
				isLoading
			}"
			@onSubmit="() => doneClicked()"
			@goTo="path => $router.push(path)"
	/>
</template>
<script>
import Template from '../components/templates/NewPassword/NewPassword';
import {c} from '@/components/constants.js'

export default {
	components: {
		Template,
	},
	methods: {
		doneClicked: async function () {
			const forms = this.params.inputs;
			await this.actions.user.passwordReset({ email: this.$route.params.email, password: forms?.password?.value, resetToken: this.$route.params.token });
			this.actions.alert.showSuccess({message: 'Updated password successfully! Please login again.'});
			this.$router.push('/login')
		}
	},
	data() {
		return {
			params: c.newPassword,
			isLoading: false,
		}
	},
}
</script>
